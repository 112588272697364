import {
  Component,
  OnInit,
  ViewChild,
  Inject,
  QueryList,
  ElementRef,
  ViewChildren,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort, Sort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ConsultantViewComponent } from "../consultant-view/consultant-view.component";
import { CommonApiService } from "./../../services/common-api.service";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { ApiCallService } from "src/app/services/api-call.service";
import { SearchInfoComponent } from "src/app/shared/search-info/search-info.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DOCUMENT, LocationStrategy } from "@angular/common";
import { SharedService } from "../../services/shared.service";
import { NonConsultantTitle, InActiveConsultantTitle, SORT_KEYS } from "../../shared/constants";
import * as XLSX from 'xlsx';

export interface PeriodicElement {
  firstName: string;
  lastName: string;
  middleName: string;
  phone: string;
  companyName: string;
  city?: string;
  rating?: number;
  country: string;
  idStatus: string;
  speciality: string;
  title: string;
  email?: string;
}

const ELEMENT_DATA: PeriodicElement[] = [];
const BULK_INVITE_DATA = [];

@Component({
  selector: "app-review-new-consultant",
  templateUrl: "./review-new-consultant.component.html",
  styleUrls: ["./review-new-consultant.component.scss"],
})
export class ReviewNewConsultantComponent implements OnInit {
  @ViewChildren("functionsCheckboxes")
  functionsCheckboxes: QueryList<ElementRef>;
  @ViewChildren("servicesCheckboxes") servicesCheckboxes: QueryList<ElementRef>;
  @ViewChildren("industriesCheckboxes")
  industriesCheckboxes: QueryList<ElementRef>;

  displayedColumns: string[] = [
    "consultantName",
    "company",
    "phoneNo",
    "speciality",
    "country",
    "status",
    "actions",
  ];
  displayedColumnsNC: string[] = [
    "consultantName",
    "userType",
    "createdBy",
    "createdDate",
    "actions",
  ];
  displayedColumns1: string[] = [];
  displayedColumns2: string[] = [
    "consultantName",
    "company",
    "createdBy",
    "createdDate",
    //"speciality",
    "status",
    "actions",
  ];
  displayedColumnsBI: string[] = ["consultantName", "company", "actions"];
  displayedColumnsBIS: string[] = ["consultantName", "company", "actions"];

  dataSource = new MatTableDataSource(ELEMENT_DATA);
  bulkInviteData = new MatTableDataSource(BULK_INVITE_DATA);

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  advanceSearchShow: boolean = true;
  showConsultantProfile: boolean = false;
  title: any;
  selectedVal = [36];
  showConsultantDetails: boolean = false;
  sendPersonId: any;
  BasicFormGroup: any;
  defaultCategory: FormGroup;
  ratingsForm: FormGroup;
  displayValuesForm: FormGroup;
  searchedCompanyName: string = "";
  loader: Boolean = true;
  defaultFilter: any;
  previewDatails: any;
  apiConfig: any;
  getConfigConsultant: any;
  timer = null;
  selectedCompany = null;
  companyDropdownData = [];
  searching = false;
  gridType: any;
  inviteAPI: any;
  selectedCountry: any;
  pageData: any;
  totalRecordCount: any;
  NonConsultantTitle: string = NonConsultantTitle;
  InActiveConsultantTitle: string = InActiveConsultantTitle;
  userRole = localStorage.getItem("LoginRole");
  searchData = null;
  storedFunctions = new Set();
  storedServices = new Set([36]);
  storedIndustries = new Set();
  SEARCH_CONSULTANT = 'Search Consultants';

  bulkInvitePageData: any;
  totalBulkInviteRecordCount: any;
  sortedData: any = [];
  exportData: any = [];
  sortingMode = null;
  editMode = false;
  isNonUSA: Boolean = false;
  storedReviews = [];
  Indicator: any = 0;
  loggedInUser = localStorage.getItem("LoginRole") == "Admin" ? true : false;
  copyToClipboard(text: string) {
    navigator.clipboard.writeText(text).then(() => {
      //alert('Copied to clipboard');

    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  }
  constructor(
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    public fb: FormBuilder,
    public commonApi: CommonApiService,
    private router: Router,
    private route: ActivatedRoute,
    private location: LocationStrategy,
    public apiCallService: ApiCallService,
    @Inject(DOCUMENT) private document: Document,
    private shareService: SharedService
  ) {
    this.apiConfig = this.apiCallService.apiendPoints.Specalities;
    this.getConfigConsultant = this.apiCallService.apiendPoints.consultant;
    this.inviteAPI = this.apiCallService.apiendPoints.invitation;
    this.selectedCountry = this.apiCallService.selectedCountry.getValue();
    this.sortedData = this.dataSource.data.slice();
    const data = this.apiCallService.searchQueryPayload.getValue();
    if (data) {
      this.seachConsultant(data);
      this.apiCallService.searchQueryPayload.next(null);
    }

    this.route.data.subscribe((data) => {
      this.title = data.title;
      this.displayedColumns1 =
        this.title == "Search Consultants"
          ? [
            "consultantName",
            "ratings",
            "company",
            "phoneNo",
            "linkedIn",
            "WebSite",
            "speciality",
            "country",
            "city",
            "UpdatedDate",
            "actions",
          ]
          : [
            "consultantName",
            "company",
            "phoneNo",
            "speciality",
            "country",
            "status",
            "actions",
          ];
    });
  }

  nestedSpecialities = [];

  consultantTypeData = [];
  specialitiesData = [];
  servicesData = [];
  industriesData = [];
  countriesData = [];
  regionData = [];
  rateModal = false;
  arr = new Array(5);
  selectedSpeciality = [36];
  setRating = 0;
  userRating = 4;
  reviewQuestions = [];
  showAll = false;
  reviewToastMsg = "submitted ";
  showMessageStripe = false;
  showErrorToast = false;
  reviewData = [];
  tempReviewArr = [];
  star1 = 0;
  star2 = 0;
  star3 = 0;
  showMore = false;
  selectedConsultents = [];
  optionInitial = "";
  serviceInitial = [36];
  industryInitial = "";

  ngOnInit() {
    this.SetBaseData();
    this.GetReviewQuestions();
    this.RatingForm();
    this.displayForm();
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.pageData = this.apiCallService.consultantPagefilter.getValue();

    this.bulkInvitePageData =
      this.apiCallService.bulkInvitePagefilter.getValue();
    this.createSearchFormGroup();
    // this.seachConsultant(this.defaultFilter)
    if (this.title == "Review New Consultants") {
      this.gridType = 4;
      this.getReviewNewConsutants();
    }
    if (
      this.title == "Search Consultants" ||
      this.title == "Send Bulk Invitations"
    ) {
      this.loader = false;
      this.gridType = 2;
      this.defaultFilter = this.apiCallService.consultantSearch.getValue();
      this.apiCallService.sortFilter.next({
        sortingBy: "",
        direction: "desc"
      })
      // this.BasicFormGroup.patchValue(this.defaultFilter);
      // this.seachConsultant(this.defaultFilter)
    }
    if (
      this.title == "Suggested Consultants" ||
      this.title == "View Suggested Consultants"
    ) {
      this.gridType = 3;
      this.getsuggestConsultants();
    }
    if (
      this.title == "Review Ratings" ||
      this.title == "View Suggest Ratings"
    ) {
      this.gridType = 5;
      this.getReviewRatings();
      // this.getsuggestConsultants();
    }
    if (this.title == NonConsultantTitle) {
      this.gridType = 6;
      // this.GetNonConsultants()
      this.getsuggestConsultants();
    }
    if (this.title == InActiveConsultantTitle) {
      this.gridType = 7;
      // this.GetNonConsultants()
      this.getsuggestConsultants();
    }

    if (window.history.state.company) {
      this.selectedCompany = window.history.state.company;
      this.BasicFormGroup.patchValue({
        company: this.selectedCompany.id,
        // companyAddressId: this.selectedCompany.companyAddressId,
        country: this.selectedCompany.address.country,
        consultantName: "",
      });
      // this.advanceSearchShow = true;
      this.apiCallService.selectedCountry.next(0);
      this.seachConsultant(this.BasicFormGroup.value);
    } else if (window.history.state.searchData) {
      let data = window.history.state.searchData;
      data = JSON.parse(JSON.stringify(eval("(" + data + ")")));
      this.BasicFormGroup.patchValue({
        company: data["companyId"],
        // companyAddressId: data["companyAddressId"],
        country: data["countryId"],
        consultantName: data["name"] == "null" ? "" : data["name"],
      });

      this.seachConsultant(data);
    } else {
      this.BasicFormGroup.patchValue({
        company: 0,
        companyAddressId: 0,
        consultantName: "",
      });
    }

    this.defaultCategory = this.fb.group({
      defaultCategory: [null, Validators.required],
    });
    const toSelect = this.servicesData.find((c) => c.id == 36);
    this.defaultCategory.get("defaultCategory").setValue(toSelect);

    this.Indicator = this.route.snapshot.paramMap.get('ind');
    if(this.Indicator == 1){
      var retrievedObject = localStorage.getItem('searchObject');
      var retrievedObject1 =  JSON.parse(retrievedObject);
      this.seachConsultant(retrievedObject1);
      localStorage.removeItem('searchObject')
    }
  }
  createSearchFormGroup() {
    this.BasicFormGroup = this.fb.group({
      consultantName: [""],
      consultantType: [""],
      speciality: [""],
      company: [""],
      companyAddressId: 0,
      country: [this.selectedCountry],
      region: [""],
      bio: [""],
      nonUSA: [false],
      hasOrAgree: [1],
    });
  }
  onPaginateChange(event: any) {
    this.apiCallService.consultantPagefilter.next(event);
    this.defaultFilter = this.apiCallService.consultantSearch.getValue();
    // this.seachConsultant(this.defaultFilter);
    if (this.title == "Review New Consultants") {
      this.gridType = 4;
      this.getReviewNewConsutants();
    } else if (this.title === NonConsultantTitle) {
      this.gridType = 6;
      this.getsuggestConsultants();
    } else if (this.title === InActiveConsultantTitle) {
      this.gridType = 7;
      this.getsuggestConsultants();
    } else {
      this.seachConsultant(this.defaultFilter);
    }
  }

  onBIPaginateChange(event: any) {
    this.apiCallService.bulkInvitePagefilter.next(event);
  }

  displayForm() {
    this.displayValuesForm = this.fb.group({
      answer1: "",
      answer2: "",
      answer3: "",
      answer4: "",
      answer5: "",
      answer6: "",
      answer7: "",
      answer8: "",
      answer9: "",
      answer10: "",
      answer11: "",
      answer12: "",
      answer13: "",
      answer14: "",
      answer15: "",
      answer16: "",
    });
  }

  RatingForm() {
    this.ratingsForm = this.fb.group({
      id: 0,
      createdBy: localStorage.getItem("LoggedInUser"),
      createdOn: new Date().toISOString(),
      lastUpdateBy: localStorage.getItem("LoggedInUser"),
      lastUpdateOn: new Date().toISOString(),
      isActive: true,
      status: 0,
      action: 0,
      personId: 0,
      personInvitedById: 0,
      personReviewedById: Number(localStorage.getItem("LoggedInUser")),
      reviewStatusId: 4751,
      comments: "",
      personReviewDetails: this.fb.array([]),
    });
  }

  SetRating(id, i, loop) {
    let review = {
      id: 0,
      createdBy: localStorage.getItem("LoggedInUser"),
      createdOn: new Date().toISOString(),
      lastUpdateBy: localStorage.getItem("LoggedInUser"),
      lastUpdateOn: new Date().toISOString(),
      isActive: true,
      status: 0,
      action: 0,
      personReviewId: 0,
      reviewQnsId: id,
      reviewAns: String(i),
      reviewQns: "",
    };
    if (this.tempReviewArr.findIndex((a) => a.reviewQnsId == id) == -1) {
      this.tempReviewArr.push(review);
    } else {
      this.tempReviewArr.find((a) => a.reviewQnsId == id).reviewAns = String(i);
    }

    if (loop == "one") {
      this.star1 = i;
    }
    if (loop == "two") {
      this.star2 = i;
    }
    if (loop == "three") {
      this.star3 = i;
    }
  }

  getISODate(date) {
    return new Date(date).toISOString();
  }
  getMoreDetails(personId: any) {
    this.apiCallService.searchQueryPayload.next(this.searchData);
    this.router.navigate(
      [
        this.userRole === "Admin"
          ? `admin/consultant-view/${personId}`
          : `user/consultant-view/${personId}`,
      ],
      { queryParams: { redirect: this.router.url } }
    );
  }

  sortData(sort: Sort) {
    if (this.gridType === 2) {
      this.apiCallService.sortFilter.next({
        sortingBy: SORT_KEYS.get(sort.active),
        direction: sort.direction
      });
      this.seachConsultant(this.BasicFormGroup.value);
    } else if (this.gridType === 3 || this.gridType === 6) {
      this.apiCallService.sortFilterSuggestedCon.next({
        sortingBy: SORT_KEYS.get(sort.active),
        direction: sort.direction
      });
      this.getsuggestConsultants();
    } else if (this.gridType === 4) {
      console.log(sort);
      this.apiCallService.sortFilterNonCon.next({
        sortingBy: SORT_KEYS.get(sort.active),
        direction: sort.direction
      });
      this.getReviewNewConsutants();
    } else if (this.gridType === 5) {
      this.apiCallService.sortFilterReviewRatings.next({
        sortingBy: SORT_KEYS.get(sort.active),
        direction: sort.direction
      });
      this.getReviewRatings();
    }
  }

  getDataForExcel(item: any) {
    this.loader = true;
    let data = {
      specialityId: item.specialityId || this.selectedSpeciality,
      companyId: item.company || item.companyId,
      countryId:
        item.country ||
        item.countryId,
      bio: item.bio || "",
      hasOrAgree: item.hasOrAgree ? 1 : 0,
      name: item.consultantName || item.name || "null",
      consultantTypeId: item.consultantTypeId || 0,
      companyAddressId: item.companyAddressId || 0,
      nonUSA: item.nonUSA || false,
      regionId: item.region || 0,
      userId: item.userId || Number(localStorage.getItem("LoggedInUser")),
      role: item.role || localStorage.getItem("LoginRole") == "Admin" ? 1 : 2,
      gridType: 2,
      pageIndex: 0,
      pageSize: 0,
    };
    this.apiCallService
      .post(this.getConfigConsultant.SearchConsultant, data)
      .subscribe((res) => {
        const {
          result: { searchConsultantResult, total },
        }: any = res;
        if (searchConsultantResult) {
          this.exportData = searchConsultantResult;
          // console.log(searchConsultantResult);
          // setTimeout(() => {
          //   let el = this.document.getElementById('exportToExcel') as HTMLButtonElement;
          //   el.click();
          // }, 1000)
          const exportData = searchConsultantResult.map(el => {
            return {
              ConsultantName: `${el.firstName} ${el.middleName} ${el.lastName}`,
              Ratings: el.rating,
              Company: el.companyName,
              Email: el.email,
              linkedIn: el.linkedIn,
              WebSite: el.webSite,
              Specialty: el.speciality,
              Country: el.country,
              City: el.city,
              UpdatedDate: el.updatedOn
            }
          });
          if (XLSX) {
            const filename = 'Consultant Search Details.xlsx';
            let ws = XLSX.utils.json_to_sheet(exportData);
            let wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "People");
            XLSX.writeFile(wb, filename);
          }

        }
        this.loader = false;
      });
  }
  exportToExcel() {
    this.getDataForExcel(this.BasicFormGroup.value);
  }

  SaveReviews(type) {
    // let isRejected = this.ratingsForm.value.reviewStatusId == 4753 ? true : false;
    this.ratingsForm.patchValue({
      reviewStatusId: type == "save" ? 4754 : 4751,
    });
    this.reviewToastMsg = type == "save" ? "saved" : "submitted";
    this.loader = true;
    this.ratingsForm.value.personReviewDetails = [];
    this.ratingsForm.value.personReviewDetails.push(...this.tempReviewArr);
    for (let i = 0; i < this.tempReviewArr.length; i++) {
      this.tempReviewArr[i].id = this.storedReviews.find(
        (a) => a.reviewQnsId == this.tempReviewArr[i].reviewQnsId
      )
        ? this.storedReviews.find(
          (a) => a.reviewQnsId == this.tempReviewArr[i].reviewQnsId
        ).id
        : 0;
    }
    if (this.editMode) {
      this.commonApi.updateReview(this.ratingsForm.value).subscribe((res) => {
        this.loader = false;
        this.rateModal = false;
        window.scrollTo(0, 0);
        this.getReviewRatings();
        if (res["status"] == 2 && res["message"].includes("Already")) {
          this.showErrorToast = true;
        } else {
          this.showMessageStripe = true;
        }
        setTimeout(() => {
          this.showMessageStripe = false;
          this.showErrorToast = false;
        }, 4000);
        this.displayValuesForm.reset();
        this.star1 = 0;
        this.star2 = 0;
        this.star3 = 0;
        this.showAll = false;
        this.reviewData = [];
        this.tempReviewArr = [];
      });
    } else {
      this.commonApi.addReview(this.ratingsForm.value).subscribe((res) => {
        this.loader = false;
        this.rateModal = false;
        window.scrollTo(0, 0);
        if (res["status"] == 2 && res["message"].includes("Already")) {
          this.showErrorToast = true;
        } else {
          this.showMessageStripe = true;
        }
        setTimeout(() => {
          this.showMessageStripe = false;
          this.showErrorToast = false;
        }, 4000);
        this.displayValuesForm.reset();
        this.star1 = 0;
        this.star2 = 0;
        this.star3 = 0;
        this.showAll = false;
        this.reviewData = [];
        this.tempReviewArr = [];
      });
    }
  }

  closeRateModal() {
    this.rateModal = false;
    this.star1 = 0;
    this.star2 = 0;
    this.star3 = 0;
    this.displayValuesForm.reset();
    this.tempReviewArr = [];
    this.showMore = false;
  }

  SetBaseData() {
    for (let i = 1; i < 7; i++) {
      this.commonApi.getBaseData(i).subscribe((data) => {
        switch (i) {
          case 1:
            this.consultantTypeData = data["result"].sort((a, b) =>
              a.baseKeyValueName.localeCompare(b.baseKeyValueName)
            );
            break;
          case 2:
            this.specialitiesData = data["result"];
            this.nestedSpecialities.push(
              ...[
                {
                  name: "Functions",
                  data: data["result"].sort((a, b) =>
                    a.baseKeyValueName.localeCompare(b.baseKeyValueName)
                  ),
                },
              ]
            );
            break;
          case 3:
            this.servicesData = data["result"];
            this.nestedSpecialities.push(
              ...[
                {
                  name: "Services",
                  data: data["result"].sort((a, b) =>
                    a.baseKeyValueName.localeCompare(b.baseKeyValueName)
                  ),
                },
              ]
            );
            break;
          case 4:
            this.industriesData = data["result"];
            this.nestedSpecialities.push(
              ...[
                {
                  name: "Industries",
                  data: data["result"].sort((a, b) =>
                    a.baseKeyValueName.localeCompare(b.baseKeyValueName)
                  ),
                },
              ]
            );
            break;
          case 5:
            this.countriesData = data["result"].sort((a, b) =>
              a.baseKeyValueName.localeCompare(b.baseKeyValueName)
            );
            break;
          case 6:
            this.regionData = data["result"].sort((a, b) =>
              a.baseKeyValueName.localeCompare(b.baseKeyValueName)
            );
            break;
        }
      });
    }
  }

  GetReviewQuestions() {
    this.commonApi.getBaseData(20).subscribe((res : any) => {
      const { result }: any = res;
      this.reviewQuestions = res.result;
      this.reviewQuestions = this.reviewQuestions.filter(
        (review) =>
        review.baseKeyValueName == 'How would you rate the process $ (Eg.Communications, Flexibility, Speed, Quality, Ethics, Slate Breadth, Assesment)?' ||
        review.baseKeyValueName == 'Did the consultant deliver as promised $ (Eg.Timeliness, Diversity of slate, Candidate closure, Added value consulting)?' ||
        review.baseKeyValueName == 'Would you use the consultant again?' ||
        review.baseKeyValueName == 'Would you be willing to be connected to other ESIX members to discuss your review?' ||
        review.baseKeyValueName == 'Comments? (These are anonymous)'
      );
      // this.reviewQuestions = data["result"];
    });
  }

  getStatusClass(status) {
    switch (status) {
      case "Open":
        return "approved";
      case "Occupied":
        return "reject";
      case "Not Available":
        return "pending";
      case "Executive Search":
        return "pending";
      case "Saved":
        return "pending";
      case "Submitted":
        return "approved";
      default:
        return "pending";
    }
  }

  getSpeciality(val) {
    return val ? val.split(",")[0] : "";
  }
  getToolTip(val) {
    val = val && [...new Set(val.split(","))].join(",");
    return val ? val.split(",").join("\n") : [];
  }

  getLastname(lastname) {
    return lastname ? lastname : "";
  }
  getMiddlename(middlename) {
    return middlename && middlename != "null" ? middlename : "";
  }

  AddConsuntant(id) {
    // this.selectedConsultents.indexOf(id) == -1 && (this.selectedConsultents.length<2 && this.selectedConsultents.push(id));
    if (this.selectedConsultents.indexOf(id) != -1) {
      this.shareService.showToast("This consultant is already added");
    } else if (this.selectedConsultents.length >= 2) {
      this.shareService.showToast("Can not add more than two consultants");
    } else {
      this.selectedConsultents.push(id);
      this.shareService.showToast("Added consultant to compare");
    }
  }

  previewConsultant(id: any) {
    this.loader = true;
    this.apiCallService.getById(this.apiConfig.previewConsultant, id).subscribe(
      (response) => {
        this.previewDatails = response["result"];
        this.loader = false;
        this.showConsultantProfile = true;
      },
      (err) => {
        console.log("Demo", err);
      }
    );
  }

  ShowRateModal(item: any, mode = "add", edit = false) {
    this.loader = true;
    this.editMode = edit;
    this.apiCallService
      .getById(this.apiConfig.previewConsultant, item.personId)
      .subscribe((response) => {
        this.previewDatails = response["result"];
        if (mode === "edit") {
          this.GetReviewData(item.reviewId, item.personId);
        }
        this.rateModal = true;
        this.loader = false;
        this.ratingsForm.patchValue({
          personId: this.previewDatails.id,
        });
      });
  }

  GetReviewData(reviewId, personId) {
    this.storedReviews = [];
    this.commonApi.getReview(reviewId, personId).subscribe((res) => {
      let data = res["result"].personReviewDetails;
      this.storedReviews = res["result"].personReviewDetails;
      this.ratingsForm.patchValue({
        reviewStatusId: res["result"].reviewStatusId,
        id: res["result"].id,
      });
      for (let i = 0; i < data.length; i++) {
        switch (data[i].reviewQnsId) {
          case 4755:
            this.star1 = data[i].reviewAns;
            break;
          case 4756:
            this.star2 = data[i].reviewAns;
            break;
          case 4757:
            this.star3 = data[i].reviewAns;
            break;
          case 4758:
            this.displayValuesForm.patchValue({
              answer1: data[i].reviewAns,
            });
            break;
          case 4759:
            this.displayValuesForm.patchValue({
              answer2: data[i].reviewAns,
            });
            break;
          case 4760:
            this.displayValuesForm.patchValue({
              answer3: data[i].reviewAns,
            });
            break;
          case 4773:
            this.displayValuesForm.patchValue({
              answer4: data[i].reviewAns,
            });
            break;
          case 4767:
            this.displayValuesForm.patchValue({
              answer5: this.getCurrentDate(data[i].reviewAns),
            });
            break;
          case 4768:
            this.displayValuesForm.patchValue({
              answer6: data[i].reviewAns,
            });
            break;
          case 4769:
            this.displayValuesForm.patchValue({
              answer7: data[i].reviewAns,
            });
            break;
          case 4770:
            this.displayValuesForm.patchValue({
              answer8: data[i].reviewAns,
            });
            break;
          case 4771:
            this.displayValuesForm.patchValue({
              answer9: data[i].reviewAns,
            });
            break;
          case 4772:
            this.displayValuesForm.patchValue({
              answer10: data[i].reviewAns,
            });
            break;
          case 4761:
            this.displayValuesForm.patchValue({
              answer11: data[i].reviewAns,
            });
            break;
          case 4762:
            this.displayValuesForm.patchValue({
              answer16: data[i].reviewAns,
            });
            break;
          case 4763:
            this.displayValuesForm.patchValue({
              answer12: data[i].reviewAns,
            });
            break;
          case 4764:
            this.displayValuesForm.patchValue({
              answer13: this.getCurrentDate(data[i].reviewAns),
            });
            break;
          case 4765:
            this.displayValuesForm.patchValue({
              answer14: this.getCurrentDate(data[i].reviewAns),
            });
            break;
          case 4766:
            this.displayValuesForm.patchValue({
              answer15: this.getCurrentDate(data[i].reviewAns),
            });

            break;
        }
      }
    });
  }

  getCurrentDate(d) {
    let date = new Date(d);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    return `${year}-${month < 10 ? "0" + month : month}-${day < 10 ? "0" + day : day
      }`;
  }

  onFormSubmit(form: FormGroup) {
    if (form.valid) {
      this.loader = true;
      let data = form.value;
      data.speciality = data.speciality || this.selectedSpeciality;
      if (
        data.consultantName == null &&
        data.company == null &&
        data.consultantType == null &&
        data.country == null &&
        data.speciality == null
      ) {
        this.shareService.showToast(
          "Please enter a search input or select any option"
        );
        this.loader = false;
      } else if (data.country == null || data.country == 0) {
        this.shareService.showToast("Please select a Country");
        this.loader = false;
      } else if (this.selectedSpeciality.length === 0) {
        this.shareService.showToast(
          "Please select at least one specialty from Industry/Services/Function"
        );
        this.loader = false;
      } else {
        this.pageData.pageIndex = 0;
        this.apiCallService.consultantSearch.next(data);
        this.apiCallService.selectedCountry.next(data.country);
        this.seachConsultant(data);
      }
    }
  }
  inviteConsultant(item: any) {
    this.loader = true;
    let data = {
      personId: item,
      registrationLink: `${this.document.location.origin}/signup/${item}`,
      invitedBy: localStorage.getItem("LoggedInUser"),
      invitedOn: new Date().toISOString(),
      status: 0,
      roleID: 3,
    };
    this.commonApi.sendBulkInvitation([data]).subscribe((res) => {
      this.shareService.showToast("Invitation sent successfully");
      this.loader = false;
    });
  }
  seachConsultant(item: any) {
    this.loader = true;
    this.pageData = this.apiCallService.consultantPagefilter.getValue();
    this.dataSource.data = [];
    let data = {
      specialityId: item.specialityId || this.selectedSpeciality || 36,
      companyId: item.company || item.companyId || 0,
      countryId:
        item.country ||
        item.countryId ||
        this.apiCallService.selectedCountry.getValue(),
      bio: item.bio || "",
      hasOrAgree: item.hasOrAgree ? 1 : 0,
      name: item.consultantName || item.name || "null",
      consultantTypeId: item.consultantTypeId || 0,
      companyAddressId: item.companyAddressId || 0,
      nonUSA: item.nonUSA || false,
      regionId: item.region || 0,
      userId: item.userId || Number(localStorage.getItem("LoggedInUser")),
      role: item.role || localStorage.getItem("LoginRole") == "Admin" ? 1 : 2,
      gridType: item.gridType || this.gridType,
      pageIndex: item.pageIndex || this.pageData["pageIndex"] || 0,
      pageSize: item.pageSize || this.pageData["pageSize"] || 50,
      ...this.apiCallService.sortFilter.getValue()
    };
    this.searchData = data;
    this.apiCallService
      .post(this.getConfigConsultant.SearchConsultant, data)
      .subscribe((res) => {
        const {
          result: { searchConsultantResult, total },
        }: any = res;
        if (searchConsultantResult) {
          this.totalRecordCount = total;
          this.sortedData = this.getFilteredByCompanyName(searchConsultantResult);
          this.dataSource.data = this.shareService.sortByRating(
            searchConsultantResult
          );

          if (searchConsultantResult.length === 0) {
            this.shareService.showToast("No records found.");
          }
        }
        this.loader = false;
      });
    // this.commonApi.searchConsultants(item.speciality, item.country, item.consultantName, item.company).subscribe(res => { console.log(res) });
  }

  getFilteredByCompanyName(data) {
    // if (!this.searchedCompanyName) {
    //   return data;
    // }
    // return data.filter((el) => el.companyName && el.companyName.toLowerCase() === this.searchedCompanyName.toLowerCase())
    return data;
  }

  getReviewRatings() {
    // review ratings listing API call here..
    this.dataSource.data = [];
    this.pageData = this.apiCallService.consultantPagefilter.getValue();
    let data = {
      specialityId: [],
      companyId: 0,
      countryId: this.selectedCountry,
      name: "null",
      consultantTypeId: 0,
      regionId: 0,
      userId: Number(localStorage.getItem("LoggedInUser")),
      role: localStorage.getItem("LoginRole") == "Admin" ? 1 : 2,
      gridType: this.gridType,
      pageIndex: this.pageData["pageIndex"] || 0,
      pageSize: this.pageData["pageSize"] || 50,
      ...this.apiCallService.sortFilterReviewRatings.getValue()
    };
    this.apiCallService
      .post(this.getConfigConsultant.SearchConsultant, data)
      .subscribe((res) => {
        this.totalRecordCount = 0;
        const {
          result: { total, searchConsultantResult },
        }: any = res;
        if (searchConsultantResult) {
          this.totalRecordCount = total;
          this.dataSource.data = searchConsultantResult;
          this.sortedData = searchConsultantResult;
          if (searchConsultantResult.length === 0) {
            this.shareService.showToast("No records found.");
          }
        }
        this.loader = false;
      });
  }

  reviewRating(personId, role) {
    if (role == "Admin") {
      this.router.navigate([`admin/review-ratings-view/${personId}`]);
    }
    if (role == "User") {
      this.router.navigate([`user/review-ratings-view/${personId}`]);
    }
  }

  getsuggestConsultants() {
    this.dataSource.data = [];
    this.pageData = this.apiCallService.consultantPagefilter.getValue();
    let data = {
      specialityId: [],
      companyId: 0,
      countryId: this.selectedCountry,
      name: "null",
      consultantTypeId: 0,
      regionId: 0,
      userId: Number(localStorage.getItem("LoggedInUser")),
      role: localStorage.getItem("LoginRole") == "Admin" ? 1 : 2,
      gridType: this.gridType,
      pageIndex: this.pageData["pageIndex"] || 0,
      pageSize: this.pageData["pageSize"] || 50,
      isActive: true,
      ...this.apiCallService.sortFilterSuggestedCon.getValue()
    };
    if (this.gridType === 6) {
      this.loader = true;
      this.commonApi.GetNonConsultants(data).subscribe((res) => {
        const { result, total }: any = res;
        this.dataSource.data = result;
        this.sortedData = result;
        this.totalRecordCount = total;
        this.loader = false;
      });
    } else if (this.gridType === 7) {
      this.loader = true;
      data.isActive = false;
      this.commonApi.GetNonConsultants(data).subscribe((res) => {
        const { result, total }: any = res;
        this.dataSource.data = result;
        this.sortedData = result;
        this.totalRecordCount = total;
        this.loader = false;
      });
    } else {
      this.apiCallService
        .post(this.getConfigConsultant.SearchConsultant, data)
        .subscribe((res) => {
          this.totalRecordCount = 0;
          const {
            result: { total, searchConsultantResult },
          }: any = res;
          if (searchConsultantResult) {
            this.totalRecordCount = total;
            this.dataSource.data = searchConsultantResult;
            this.sortedData = searchConsultantResult;
            if (searchConsultantResult.length === 0) {
              this.shareService.showToast("No records found.");
            }
          }
          this.loader = false;
        });
    }
  }

  getPersonType(id: number): string {
    switch (id) {
      case 1:
        return "execSmart Admin";
      case 2:
        return "execSmart Member";
      default:
        return "Consultant";
    }
  }

  getReviewNewConsutants() {
    this.dataSource.data = [];
    this.loader = true;
    this.pageData = this.apiCallService.consultantPagefilter.getValue();
    let data = {
      specialityId: [],
      companyId: 0,
      countryId: this.selectedCountry,
      name: "null",
      consultantTypeId: 0,
      regionId: 0,
      userId: Number(localStorage.getItem("LoggedInUser")),
      role: localStorage.getItem("LoginRole") == "Admin" ? 1 : 2,
      gridType: this.gridType,
      pageIndex: this.pageData["pageIndex"] || 0,
      pageSize: this.pageData["pageSize"] || 50,
      ...this.apiCallService.sortFilterNonCon.getValue()
    };
    this.apiCallService
      .post(this.getConfigConsultant.SearchConsultant, data)
      .subscribe((res) => {
        this.totalRecordCount = 0;
        const {
          result: { total, searchConsultantResult },
        }: any = res;
        if (searchConsultantResult) {
          this.totalRecordCount = total;
          this.dataSource.data = searchConsultantResult;
          this.sortedData = searchConsultantResult;
          if (searchConsultantResult.length === 0) {
            this.shareService.showToast("No records found.");
          }
        }
        this.loader = false;
      });
  }
  // GET COMPANY DATA
  getCompanyData(event) {
    let key = event.target.value;
    if (!key) this.companyDropdownData = [];
    if (key == "") {
      this.selectedCompany = null;
      this.BasicFormGroup.patchValue({
        company: 0,
      });
    }
    if (key.length > 3) {
      this.searchedCompanyName = key;
      this.searching = true;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.commonApi.getCompaniesbyid(key).subscribe((res) => {
          (this.companyDropdownData = res["result"]), (this.searching = false);
        });
      }, 600);
    }
  }

  // SELECT COMPANY
  selectCompany(data) {
    this.selectedCompany = data;
    this.searchedCompanyName = data.companyName;
    this.BasicFormGroup.patchValue({
      company: data.id,
      companyAddressId: data.companyAddressId,
    });
    this.companyDropdownData = [];
  }

  helpSearchDoc() {
    const dialogRef = this.dialog.open(SearchInfoComponent, {
      data: { name: "dmo" },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }
  clearFilter() {
    // reset filter Selection...
    this.BasicFormGroup.reset();
    this.isNonUSA = false;
    // this.advanceSearchShow = !this.advanceSearchShow;
    this.dataSource.data = [];
    this.defaultFilter = this.apiCallService.consultantSearch.getValue();
    this.selectedSpeciality = [36];
    this.selectedVal = [36];
    this.selectedCompany = null;
    this.BasicFormGroup.patchValue({
      company: 0,
      companyAddressId: 0,
      consultantName: "",
      country: 184,
      hasOrAgree: [1],
      speciality: [36],
    });
    this.dataSource.data = [];
    this.sortedData = [];
    this.totalRecordCount = 0;
    this.uncheckAll();
    console.log(this.servicesCheckboxes);
  }
  actionClick(item: string, personId: any) {
    if (item == "Admin") {
      this.router.navigate([`admin/consultant-view/${personId}`]);
    }
    if (item == "User") {
      this.router.navigate([`user/consultant-view/${personId}`]);
    }
  }
  reviewConsultant(item: string, ind: any, personId: any) {
    if (item == "Admin") {
      localStorage.setItem('searchObject', JSON.stringify(this.searchData));

      this.router.navigate([`admin/review-consultant/${ind}/${personId}`]);
    }
    if (item == "User") {
      this.router.navigate([`user/review-consultant/${personId}`]);
    }
    if (item == "NonUser") {
      this.router.navigate([`admin/review-user/${ind}/${personId}`]);
    }
    if (item == "Both") {
      this.router.navigate([
        this.loggedInUser
          ? `admin/review-ratings-view`
          : `user/review-ratings-view`,
      ]);
    }
  }

  getMatLabel() {
    return window.location.pathname.includes("review-new-consultant")
      ? "Search to review consultant"
      : "Search for consultant";
  }

  getBackground(row) {
    return this.previewDatails && this.previewDatails.id == row.personId
      ? true
      : this.selectedConsultents.indexOf(row.personId) == -1
        ? false
        : true;
  }

  gotoCompare(role) {
    let [personId1, personId2] = this.selectedConsultents;
    if (role == "Admin") {
      this.router.navigate([
        `admin/compare-consultant/${personId1}/${personId2}`,
      ]);
    }
    if (role == "User") {
      this.router.navigate([
        `user/compare-consultant/${personId1}/${personId2}`,
      ]);
    }
  }

  getConvertedDate(date) {
    let d = date ? new Date(date) : new Date();
    let tmp = d.toDateString().split(" ");
    let afterDate = tmp[2] + " " + tmp[1] + ", " + tmp[3];
    let time = d.toLocaleTimeString().split(":");
    let afterTime = time[0] + ":" + time[1]// + " " + time[2].split(" ")[1];
    return afterDate + " " + afterTime;
  }

  addToBulkCompare(data) {
    this.loader = true;
    let arr = this.bulkInviteData.data;
    if (arr.find((a) => a.personId == data.personId)) {
      this.removeFromList(data.personId, data.firstName);
    } else {
      arr.push(data);
      this.totalBulkInviteRecordCount = arr.length;
      this.bulkInviteData.data = [...arr];
      this.shareService.showToast(`Added ${data.firstName} to Bulk invitation`);
    }
    this.loader = false;
  }

  removeFromList(id, name) {
    let arr = this.bulkInviteData.data;
    let index = arr.findIndex((a) => a.personId == id);
    arr.splice(index, 1);
    this.bulkInviteData.data = [...arr];
    this.totalBulkInviteRecordCount = arr.length;
    this.shareService.showToast(`Removed ${name} from Bulk invitation`);
  }

  clearAll() {
    this.loader = true;
    this.bulkInviteData.data = [];
    this.totalBulkInviteRecordCount = this.bulkInviteData.data.length;
    this.shareService.showToast("Removed all consultants from Bulk invitation");
    this.loader = false;
  }

  shouldBeChecked(id) {
    return this.bulkInviteData.data.findIndex((a) => a.personId == id) !== -1;
  }

  storeSpeciality(set, val) {
    if (set.has(val.id)) {
      set.delete(val.id);
    } else {
      set.add(val.id);
    }
    if (this.selectedSpeciality.includes(val.id)) {
      let index = this.selectedSpeciality.findIndex((a) => a == val.id);
      this.selectedSpeciality.splice(index, 1);
    } else {
      this.selectedSpeciality.push(val.id);
    }
  }

  // clearSpeciality(){
  //   this.selectedSpeciality=[];
  // }
  // shouldBeSpecialityChecked(id){
  //   return this.selectedSpeciality.findIndex(a => a == id) != -1;
  // }

  disableInviteButton() {
    return this.bulkInviteData.data.length === 0 ? true : null;
  }

  sendInvite() {
    this.loader = true;
    let inviteData = [];
    let arr = this.bulkInviteData.data;
    for (let i = 0; i < arr.length; i++) {
      inviteData.push({
        personId: arr[i].personId,
        registrationLink: `${this.document.location.origin}/signup/${arr[i].personId}`,
        invitedBy: localStorage.getItem("LoggedInUser"),
        invitedOn: new Date().toISOString(),
        roleID: 3,
        status: 0,
      });
    }
    this.commonApi.sendBulkInvitation(inviteData).subscribe((res) => {
      this.shareService.showToast("Bulk invitation sent");
      this.loader = false;
      this.bulkInviteData.data = [];
    });
  }

  shouldBeVisible() {
    return (
      this.title == "View Suggested Consultants" &&
      localStorage.getItem("LoginRole") == "User"
    );
  }

  getEmail(email) {
    return email ? "mailto:" + email : "";
  }

  shouldEditRatingVisible(status) {
    return (
      (this.title == "View Suggest Ratings" ||
        this.title == "Review Ratings") &&
      (status == "Rejected" || status == "Saved")
    );
  }

  GetCompanyName(company) {
    return (
      company.companyName +
      (company.address.cityName ? ", " + company.address.cityName : "") +
      (company.address.countryName ? ", " + company.address.countryName : "")
    );
  }

  uncheckAll() {
    this.functionsCheckboxes.forEach((element) => {
      element["checked"] = false;
    });
    this.servicesCheckboxes.forEach((element) => {
      element["checked"] = element["value"] === 36;
    });
    this.industriesCheckboxes.forEach((element) => {
      element["checked"] = false;
    });
  }

  updateNonUSA() {
    this.isNonUSA = !this.isNonUSA;
    this.BasicFormGroup.patchValue({
      country: 184,
      nonUSA: this.isNonUSA,
    });
  }

  getSpecialty(specialty) {
    if (!specialty) return;
    return specialty.split(",").join("\n");
  }

  closeConsultantDetails() {
    this.showConsultantProfile = false;
    this.previewDatails = null;
  }
  openDialog() {
    this.dialog.open(ConsultantViewComponent, {
      data: {
        animal: "panda",
      },
    });
  }
  openRadioDialog() {
    this.dialog.open(SelectUserDialog);
  }
}

@Component({
  selector: "select-user-dialouge",
  templateUrl: "select-user-dialouge.component.html",
  styleUrls: ["./select-user-dialouge.component.scss"],
})
export class SelectUserDialog {
  open: string = "user";
  constructor(
    public form: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog
  ) { }
  setUser(event) {
    this.open = event.value;
  }
  routeToAdd() {
    this.router.navigate([
      this.open === "admin" ? "/admin/add-new-admin" : "/admin/add-new-user",
    ]);
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
