import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort, Sort } from "@angular/material/sort";
import { ApiCallService } from "src/app/services/api-call.service";
import { MatPaginator } from "@angular/material/paginator";
import { CommonApiService } from "./../../services/common-api.service";
import { NgxPermissionsService } from "ngx-permissions";
import { Router, ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { SearchInfoComponent } from "../../shared/search-info/search-info.component";
import { SharedService } from "../../services/shared.service";
import { DOCUMENT, LocationStrategy } from "@angular/common";
import { title } from "process";
import { FormBuilder, FormGroup } from "@angular/forms";
import { SORT_KEYS } from "src/app/shared/constants";

export interface PeriodicElement {
  firstName: string;
  lastName: string;
  middleName: string;
  phone: string;
  rating?: number;
  companyName: string;
  country: string;
  idStatus: string;
  speciality: string;
  title: string;
  email?: string;
}

const ELEMENT_DATA: PeriodicElement[] = [];

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  displayedColumns: string[] = [
    "consultantName",
    "ratings",
    "company",
    "phoneNo",
    "speciality",
    "country",
    "actions",
  ];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  apiConfig: any;
  sortedData: any = [];
  loader: Boolean = true;
  showConsultantProfile: boolean = false;
  showConsultantDetails: boolean = false;
  sendPersonId: any;
  selectedCountry: any;
  ceoData: any;
  cioData: any;
  cfoData: any;
  reviewsData: any;
  consultantCount: any;
  ratingsForm: FormGroup;
  displayValuesForm: FormGroup;
  consultanNewtCount: any;
  gridType: any;
  activeClass: any;
  countriesData: any;
  showMessageStripe = false;
  previewDatails: any;
  reviewToastMsg = "submitted ";
  reviewData = [];
  selectedConsultents = [];
  userRole = localStorage.getItem("LoginRole");
  gridTitle: any;
  searchData = null;
  editMode = false;
  arr = new Array(5);
  storedReviews = [];
  setRating = 0;
  userRating = 4;
  showErrorToast = false;
  totalRecordCount: any;
  rateModal = false;
  specialitiesData = [];
  industriesData = [];
  defaultFilter: any;
  pageData: any;
  reviewQuestions = [];
  tempReviewArr = [];
  getConfigConsultant: any;
  star1 = 0;
  star2 = 0;
  star3 = 0;
  showMore = false;
  sortingMode = null;
  showAll: boolean;
  loggedInUser = localStorage.getItem("LoginRole") == "Admin" ? true : false;
  constructor(
    public commonApi: CommonApiService,
    private router: Router,
    private route: ActivatedRoute,
    private permissionsService: NgxPermissionsService,
    public apiCallService: ApiCallService,
    private dialog: MatDialog,
    @Inject(DOCUMENT) private document: Document,
    public fb: FormBuilder,
    private sharedService: SharedService
  ) {
    this.apiConfig = this.apiCallService.apiendPoints.Specalities;
    this.getConfigConsultant = this.apiCallService.apiendPoints.consultant;
    this.selectedCountry = this.apiCallService.selectedCountry.getValue();
    this.defaultFilter = this.apiCallService.dashboardSpeciality.getValue();
    this.pageData = this.apiCallService.dashboardPagefilter.getValue();
    this.sortedData = this.dataSource.data.slice();
    const data = this.apiCallService.searchQueryPayload.getValue();
    if (data) {
      this.seachConsultant(data);
      this.apiCallService.searchQueryPayload.next(null);
    }
  }

  ngOnInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.getSpecalities(this.selectedCountry || 184);
    this.getCountry();
    this.SetBaseData();
    this.GetReviewQuestions();
    this.RatingForm();
    this.displayForm();
    this.getConsultantCount(this.selectedCountry || 184);
    this.getReviewNewConsultantCount(this.selectedCountry || 184);
    this.getReviewsCountData(this.selectedCountry || 184);
    if (window.history.state && window.history.state.showMessage) {
      this.helpSearchDoc();
    }
  }
  copyToClipboard(text: string) {
    navigator.clipboard.writeText(text).then(() => {
      //alert('Copied to clipboard');

    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  }
  onPaginateChange(event: any) {
    this.apiCallService.dashboardPagefilter.next(event);
    this.seachConsultant(this.defaultFilter);
  }
  getMoreDetails(personId: any) {
    this.apiCallService.searchQueryPayload.next(this.searchData);
    this.router.navigate(
      [
        this.userRole === "Admin"
          ? `admin/consultant-view/${personId}`
          : `user/consultant-view/${personId}`,
      ],
      { queryParams: { redirect: this.router.url } }
    );
  }
  closeMoreDetails() {
    this.showConsultantDetails = false;
  }
  getCountry() {
    this.commonApi.getBaseData(5).subscribe((data) => {
      this.countriesData = data["result"]
        .filter((el) => el.isActive)
        .sort((a, b) => a.baseKeyValueName.localeCompare(b.baseKeyValueName));
    });
  }

  GetReviewQuestions() {
    this.commonApi.getBaseData(20).subscribe((data) => {
      this.reviewQuestions = data["result"];
    });
  }
  RatingForm() {
    this.ratingsForm = this.fb.group({
      id: 0,
      createdBy: localStorage.getItem("LoggedInUser"),
      createdOn: new Date().toISOString(),
      lastUpdateBy: localStorage.getItem("LoggedInUser"),
      lastUpdateOn: new Date().toISOString(),
      isActive: true,
      status: 0,
      action: 0,
      personId: 0,
      personInvitedById: 0,
      personReviewedById: Number(localStorage.getItem("LoggedInUser")),
      reviewStatusId: 4751,
      comments: "",
      personReviewDetails: this.fb.array([]),
    });
  }

  SetBaseData() {
    this.commonApi.getBaseData(4).subscribe((data) => {
      this.industriesData = data["result"];
    });
    this.commonApi.getBaseData(2).subscribe((data) => {
      this.specialitiesData = data["result"];
    });
  }

  SetRating(id, i, loop) {
    let review = {
      id: 0,
      createdBy: localStorage.getItem("LoggedInUser"),
      createdOn: new Date().toISOString(),
      lastUpdateBy: localStorage.getItem("LoggedInUser"),
      lastUpdateOn: new Date().toISOString(),
      isActive: true,
      status: 0,
      action: 0,
      personReviewId: 0,
      reviewQnsId: id,
      reviewAns: String(i),
      reviewQns: "",
    };
    if (this.tempReviewArr.findIndex((a) => a.reviewQnsId == id) == -1) {
      this.tempReviewArr.push(review);
    } else {
      this.tempReviewArr.find((a) => a.reviewQnsId == id).reviewAns = String(i);
    }

    if (loop == "one") {
      this.star1 = i;
    }
    if (loop == "two") {
      this.star2 = i;
    }
    if (loop == "three") {
      this.star3 = i;
    }
  }

  closeRateModal() {
    this.rateModal = false;
    this.star1 = 0;
    this.star2 = 0;
    this.star3 = 0;
    this.displayValuesForm.reset();
    this.tempReviewArr = [];
    this.showMore = false;
  }

  onchangeCountry(country: any) {
    this.apiCallService.selectedCountry.next(Number(country));
    this.apiCallService.dashboardPagefilter.next({
      pageIndex: 0,
      pageSize: 10,
    });
    this.ceoData = {};
    this.cioData = {};
    this.cfoData = {};
    // this.reviewsData = {};
    this.selectedCountry = Number(country);
    this.getConsultantCount(Number(country));
    this.getReviewNewConsultantCount(Number(country));
    this.getReviewsCountData(Number(country));
    this.getSpecalities(Number(country));
  }
  getLastname(lastname) {
    return lastname ? lastname : "";
  }
  getMiddlename(middlename) {
    return middlename && middlename != "null" ? middlename : "";
  }
  getConsultantCount(country) {
    this.apiCallService
      .getCountByCountry(
        this.apiConfig.consultantCount,
        country,
        localStorage.getItem("LoggedInUser")
      )
      .subscribe(
        (response) => {
          this.consultantCount = response["result"];
        },
        (err) => {
          console.log("Error", err);
        }
      );
  }
  getReviewNewConsultantCount(country) {
    this.apiCallService
      .getCountByCountry(
        this.apiConfig.reviewNewConsultantCount,
        country,
        localStorage.getItem("LoggedInUser")
      )
      .subscribe(
        (response) => {
          this.consultanNewtCount = response["result"];
        },
        (err) => {
          console.log("Error", err);
        }
      );
  }

  getBackground(row) {
    return this.previewDatails && this.previewDatails.id == row.personId
      ? true
      : this.selectedConsultents.indexOf(row.personId) == -1
        ? false
        : true;
  }

  gotoCompare(role) {
    let [personId1, personId2] = this.selectedConsultents;
    if (role == "Admin") {
      this.router.navigate([
        `admin/compare-consultant/${personId1}/${personId2}`,
      ]);
    }
    if (role == "User") {
      this.router.navigate([
        `user/compare-consultant/${personId1}/${personId2}`,
      ]);
    }
  }

  AddConsuntant(id) {
    if (this.selectedConsultents.indexOf(id) != -1) {
      this.sharedService.showToast("This consultant is already added");
    } else if (this.selectedConsultents.length >= 2) {
      this.sharedService.showToast("Can not add more than two consultants");
    } else {
      this.selectedConsultents.push(id);
      this.sharedService.showToast("Added consultant to compare");
    }
  }

  getReviewRatings() {
    // review ratings listing API call here..
    this.dataSource.data = [];
    this.pageData = this.apiCallService.consultantPagefilter.getValue();
    let data = {
      specialityId: [],
      companyId: 0,
      countryId: this.selectedCountry,
      name: "null",
      consultantTypeId: 0,
      regionId: 0,
      userId: Number(localStorage.getItem("LoggedInUser")),
      role: localStorage.getItem("LoginRole") == "Admin" ? 1 : 2,
      gridType: this.gridType,
      pageIndex: this.pageData["pageIndex"] || 0,
      pageSize: this.pageData["pageSize"] || 50,
    };
    this.apiCallService
      .post(this.getConfigConsultant.SearchConsultant, data)
      .subscribe((res) => {
        this.totalRecordCount = 0;
        const {
          result: { total, searchConsultantResult },
        }: any = res;
        if (searchConsultantResult) {
          this.totalRecordCount = total;
          this.dataSource.data = searchConsultantResult;
          this.sortedData = searchConsultantResult;
          if (searchConsultantResult.length === 0) {
            this.sharedService.showToast("No records found.");
          }
        }
        this.loader = false;
      });
  }

  SaveReviews(type) {
    // let isRejected = this.ratingsForm.value.reviewStatusId == 4753 ? true : false;
    this.ratingsForm.patchValue({
      reviewStatusId: type == "save" ? 4754 : 4751,
    });
    this.reviewToastMsg = type == "save" ? "saved" : "submitted";
    this.loader = true;
    this.ratingsForm.value.personReviewDetails = [];
    this.ratingsForm.value.personReviewDetails.push(...this.tempReviewArr);
    for (let i = 0; i < this.tempReviewArr.length; i++) {
      this.tempReviewArr[i].id = this.storedReviews.find(
        (a) => a.reviewQnsId == this.tempReviewArr[i].reviewQnsId
      )
        ? this.storedReviews.find(
          (a) => a.reviewQnsId == this.tempReviewArr[i].reviewQnsId
        ).id
        : 0;
    }
    if (this.editMode) {
      this.commonApi.updateReview(this.ratingsForm.value).subscribe((res) => {
        this.loader = false;
        this.rateModal = false;
        window.scrollTo(0, 0);
        this.getReviewRatings();
        if (res["status"] == 2 && res["message"].includes("Already")) {
          this.showErrorToast = true;
        } else {
          this.showMessageStripe = true;
        }
        setTimeout(() => {
          this.showMessageStripe = false;
          this.showErrorToast = false;
        }, 4000);
        this.displayValuesForm.reset();
        this.star1 = 0;
        this.star2 = 0;
        this.star3 = 0;
        this.showAll = false;
        this.reviewData = [];
        this.tempReviewArr = [];
      });
    } else {
      this.commonApi.addReview(this.ratingsForm.value).subscribe((res) => {
        this.loader = false;
        this.rateModal = false;
        window.scrollTo(0, 0);
        if (res["status"] == 2 && res["message"].includes("Already")) {
          this.showErrorToast = true;
        } else {
          this.showMessageStripe = true;
        }
        setTimeout(() => {
          this.showMessageStripe = false;
          this.showErrorToast = false;
        }, 4000);
        this.displayValuesForm.reset();
        this.star1 = 0;
        this.star2 = 0;
        this.star3 = 0;
        this.showAll = false;
        this.reviewData = [];
        this.tempReviewArr = [];
      });
    }
  }

  displayForm() {
    this.displayValuesForm = this.fb.group({
      answer1: "",
      answer2: "",
      answer3: "",
      answer4: "",
      answer5: "",
      answer6: "",
      answer7: "",
      answer8: "",
      answer9: "",
      answer10: "",
      answer11: "",
      answer12: "",
      answer13: "",
      answer14: "",
      answer15: "",
      answer16: "",
    });
  }

  getReviewsCountData(country) {
    this.apiCallService
      .getCountByCountry(
        this.apiConfig.reviewdataUrl,
        country,
        localStorage.getItem("LoggedInUser")
      )
      .subscribe(
        (response) => {
          this.reviewsData = response["result"];
        },
        (err) => {
          console.log("Error", err);
        }
      );
  }

  // getConsultantsData() {
  //   this.commonApi.getConsultants().subscribe(data => console.log(data));
  // }
  getSpecalities(country) {
    this.apiCallService
      .getCountByCountry(
        this.apiConfig.GetSpecialitiesCount,
        country,
        localStorage.getItem("LoggedInUser")
      )
      .subscribe(
        (response) => {
          if (response["result"] && response["result"].length > 0) {
            response["result"].forEach((item) => {
              if (item.specialityName == "CEO") {
                this.ceoData = item;
              }
              if (item.specialityName == "CIO") {
                this.cioData = item;
              }
              if (item.specialityName == "CFO") {
                this.cfoData = item;
              }
            });
            this.loader = false;
            // this.seachConsultant(this.defaultFilter);
          } else {
            this.seachConsultant(this.defaultFilter);
          }
        },
        (err) => {
          console.log("Error", err);
        }
      );
  }
  actionClick(item: string, personId: any) {
    if (item == "Admin") {
      this.router.navigate([`admin/consultant-view/${personId}`]);
    }
    if (item == "User") {
      this.router.navigate([`user/consultant-view/${personId}`]);
    }
  }
  actionCards(item: any, title: any) {
    this.apiCallService.dashboardSpeciality.next({
      specialityId: item.specialityId,
      specialityName: title,
    });
    this.defaultFilter = {
      specialityId: item.specialityId,
      specialityName: title,
    };
    this.apiCallService.dashboardPagefilter.next({
      pageIndex: 0,
      pageSize: 10,
    });
    this.seachConsultant(this.defaultFilter);
  }
  previewConsultant(item: any) {
    this.apiCallService
      .getById(this.apiConfig.previewConsultant, item.personId)
      .subscribe(
        (response) => {
          this.previewDatails = response["result"];
          this.showConsultantProfile = true;
        },
        (err) => {
          console.log("Error", err);
        }
      );
  }
  closeConsultantDetails() {
    this.showConsultantProfile = false;
  }
  seachConsultant(item: any) {
    this.activeClass = (item && item.specialityName) || title;
    this.gridTitle = (item && item.specialityName) || title;
    this.loader = true;
    // this.dataSource.data = [];
    this.pageData = this.apiCallService.dashboardPagefilter.getValue();
    let data = {
      specialityId:
        (item && typeof item.specialityId === "object"
          ? item.specialityId
          : [item.specialityId]) || [],
      companyId: item.companyId || 0,
      countryId: item.countryId || this.selectedCountry,
      name: "null",
      consultantTypeId: item.consultantTypeId || 0,
      bio: "",
      hasOrAgree: 0,
      regionId: 0,
      userId: Number(localStorage.getItem("LoggedInUser")),
      role: localStorage.getItem("LoginRole") == "Admin" ? 1 : 2,
      gridType: 1,
      pageIndex: item.pageIndex || this.pageData.pageIndex,
      pageSize: item.pageSize || this.pageData.pageSize,
      ...this.apiCallService.sortDefaultFilter.getValue()
    };
    this.searchData = data;
    if (item) {
      this.apiCallService
        .post(this.getConfigConsultant.SearchConsultant, data)
        .subscribe((res) => {
          this.totalRecordCount = 0;
          const { result }: any = res;
          if (result.searchConsultantResult.length > 0) {
            const { total, searchConsultantResult }: any = result;
            this.totalRecordCount = total;
            this.sortedData = searchConsultantResult;
            this.dataSource.data = this.sharedService.sortByRating(searchConsultantResult);
          }
          this.loader = false;
        });
    } else {
      this.loader = false;
    }
  }
  getSpecialty(specialty) {
    return specialty.split(",").join("\n");
  }
  getToolTip(val) {
    val = val && [...new Set(val.split(","))].join(",");
    return val ? val.split(",").join("\n") : [];
  }

  getEmail(email) {
    return email ? "mailto:" + email : "";
  }

  helpSearchDoc() {
    const dialogRef = this.dialog.open(SearchInfoComponent, {
      data: { name: "dmo" },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog open`);
    });
  }

  reviewRating(personId, role) {
    if (role == "Admin") {
      this.router.navigate([`admin/review-ratings-view/${personId}`]);
    }
    if (role == "User") {
      this.router.navigate([`user/review-ratings-view/${personId}`]);
    }
  }

  sortData(sort: Sort) {
    this.apiCallService.sortDefaultFilter.next({
      sortingBy: SORT_KEYS.get(sort.active),
      direction: sort.direction
    });
    this.seachConsultant(this.defaultFilter);
    // const data = this.dataSource.filteredData.slice();
    // if (!sort.active || sort.direction === "") {
    //   this.sortedData = data;
    //   return;
    // } else {
    //   this.sortedData = data.sort((a, b) => {
    //     const isAsc = sort.direction === "asc";
    //     switch (sort.active) {
    //       case "consultantName":
    //         return compare(a.firstName, b.firstName, isAsc);
    //       case "company":
    //         return compare(a.companyName, b.companyName, isAsc);
    //       case "country":
    //         return compare(a.country, b.country, isAsc);
    //       case "email":
    //         return compare(a.email, b.email, isAsc);
    //       case "ratings":
    //         return compare((a.rating || 0), (b.rating || 0), isAsc);
    //       default:
    //         return 0;
    //     }
    //   });
    // }
  }

  getCurrentDate(d) {
    let date = new Date(d);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    return `${year}-${month < 10 ? "0" + month : month}-${day < 10 ? "0" + day : day
      }`;
  }

  getPersonAddress(address) {
    const {
      line1,
      line2,
      line3,
      cityName,
      stateName,
      countryName,
      postalCode,
    }: any = address;
    return `${line1 || ""}, ${line2 || ""}, ${line3 || ""}, ${cityName || ""
      }, ${stateName || ""}, ${countryName || ""}, ${postalCode || ""}`;
  }

  GetReviewData(reviewId, personId) {
    this.storedReviews = [];
    this.commonApi.getReview(reviewId, personId).subscribe((res) => {
      let data = res["result"].personReviewDetails;
      this.storedReviews = res["result"].personReviewDetails;
      this.ratingsForm.patchValue({
        reviewStatusId: res["result"].reviewStatusId,
        id: res["result"].id,
      });
      for (let i = 0; i < data.length; i++) {
        switch (data[i].reviewQnsId) {
          case 4755:
            this.star1 = data[i].reviewAns;
            break;
          case 4756:
            this.star2 = data[i].reviewAns;
            break;
          case 4757:
            this.star3 = data[i].reviewAns;
            break;
          case 4758:
            this.displayValuesForm.patchValue({
              answer1: data[i].reviewAns,
            });
            break;
          case 4759:
            this.displayValuesForm.patchValue({
              answer2: data[i].reviewAns,
            });
            break;
          case 4760:
            this.displayValuesForm.patchValue({
              answer3: data[i].reviewAns,
            });
            break;
          case 4773:
            this.displayValuesForm.patchValue({
              answer4: data[i].reviewAns,
            });
            break;
          case 4767:
            this.displayValuesForm.patchValue({
              answer5: this.getCurrentDate(data[i].reviewAns),
            });
            break;
          case 4768:
            this.displayValuesForm.patchValue({
              answer6: data[i].reviewAns,
            });
            break;
          case 4769:
            this.displayValuesForm.patchValue({
              answer7: data[i].reviewAns,
            });
            break;
          case 4770:
            this.displayValuesForm.patchValue({
              answer8: data[i].reviewAns,
            });
            break;
          case 4771:
            this.displayValuesForm.patchValue({
              answer9: data[i].reviewAns,
            });
            break;
          case 4772:
            this.displayValuesForm.patchValue({
              answer10: data[i].reviewAns,
            });
            break;
          case 4761:
            this.displayValuesForm.patchValue({
              answer11: data[i].reviewAns,
            });
            break;
          case 4762:
            this.displayValuesForm.patchValue({
              answer16: data[i].reviewAns,
            });
            break;
          case 4763:
            this.displayValuesForm.patchValue({
              answer12: data[i].reviewAns,
            });
            break;
          case 4764:
            this.displayValuesForm.patchValue({
              answer13: this.getCurrentDate(data[i].reviewAns),
            });
            break;
          case 4765:
            this.displayValuesForm.patchValue({
              answer14: this.getCurrentDate(data[i].reviewAns),
            });
            break;
          case 4766:
            this.displayValuesForm.patchValue({
              answer15: this.getCurrentDate(data[i].reviewAns),
            });

            break;
        }
      }
    });
  }

  ShowRateModal(item: any, mode = "add", edit = false) {
    this.loader = true;
    this.editMode = edit;
    this.apiCallService
      .getById(this.apiConfig.previewConsultant, item.personId)
      .subscribe((response) => {
        this.previewDatails = response["result"];
        if (mode === "edit") {
          this.GetReviewData(item.reviewId, item.personId);
        }
        this.rateModal = true;
        this.loader = false;
        this.ratingsForm.patchValue({
          personId: this.previewDatails.id,
        });
      });
  }

  inviteConsultant(item: any) {
    this.loader = true;
    let data = {
      personId: item,
      registrationLink: `${this.document.location.origin}/signup/${item}`,
      invitedBy: localStorage.getItem("LoggedInUser"),
      invitedOn: new Date().toISOString(),
      status: 0,
      roleID: 3,
    };
    this.commonApi.sendBulkInvitation([data]).subscribe((res) => {
      this.sharedService.showToast("Invitation sent successfully");
      this.loader = false;
    });
  }

  reviewConsultant(item: string, ind: any, personId: any) {
    if (item == "Admin") {
      this.router.navigate([`admin/review-consultant/${ind}/${personId}`]);
    }
    if (item == "User") {
      this.router.navigate([`user/review-consultant/${personId}`]);
    }
    if (item == "NonUser") {
      this.router.navigate([`admin/review-user/${ind}/${personId}`]);
    }
    if (item == "Both") {
      this.router.navigate([
        this.loggedInUser
          ? `admin/review-ratings-view`
          : `user/review-ratings-view`,
      ]);
    }
  }

  getStatusClass(status) {
    switch (status) {
      case "Open":
        return "approved";
      case "Occupied":
        return "reject";
      case "Not Available":
        return "pending";
      case "Executive Search":
        return "pending";
      default:
        return "approved";
    }
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
